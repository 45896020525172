@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-white font-secondary text-primary;
  }
  .h1 {
    @apply mb-2 font-primary text-[54px] font-bold capitalize leading-[120%] tracking-[-0.05em] lg:text-[108px];
  }
  .h3 {
    @apply mb-2 font-primary text-[32px] font-bold capitalize leading-[50%] tracking-[-0.05em] lg:text-[52px];
  }
  .section {
    @apply h-screen w-screen;
  }
  .btn {
    @apply flex h-[66px] items-center justify-center bg-primary py-[18px] px-[50px] font-secondary text-base font-semibold text-white;
  }
}
